.logo {
  width: 125px;
  border-radius: 6px;
  margin-right: 20px;
  float: right;
  color: #cfd8dc;
  padding-top: 3px;
}

.logoImage {
  position: relative;
  top: 25%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

body {
  background-color: rgb(236, 239, 241);
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.regular-table > div > div:nth-child(1) {
  position: absolute !important;
  top: 1px;
  right: 1px;
  border: 0 !important;
  padding: 0px !important;
  min-height: 20px !important;
}

.regular-table table {
  table-layout: auto;
}

.total-tables table {
  table-layout: auto;
}

.rdrDateDisplay {
  padding: 16px 12px 12px 12px;
}

.rdrDateDisplayWrapper {
  padding: 5px 0;
  background: rgb(239, 242, 247);
}

